import { useBlocker } from "../../contexts";
import { DefaultBlocker } from ".";

var Blocker = function Blocker() {
  var blocker = useBlocker();
  var _blocker$blocker = blocker.blocker,
      visualType = _blocker$blocker.visualType,
      visualThumbnail = _blocker$blocker.visualThumbnail;

  if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */
  } else {
    return h(DefaultBlocker, null);
  }
};

export { Blocker };