import _extends from "@babel/runtime/helpers/extends";
import { useRef } from "react";
import { Fragment } from "react";
import { useBlocker } from "../../contexts";
import { BlockerContentInlineStyles, BlockerHeaderInlineStyles, BlockerFooterInlineStyles, BlockerFooter, BlockerHeader, BlockerBody } from ".";
import { useWaitElementInViewport } from "../../hooks";

var DefaultBlocker = function DefaultBlocker(_ref) {
  var closeIcon = _ref.closeIcon;
  var blocker = useBlocker();
  var showFooter = blocker.showFooter,
      paintMode = blocker.paintMode;
  var ref = useRef(); // Lazy load background image

  var isBackgroundVisible = paintMode === "instantInViewport" || useWaitElementInViewport(ref);
  return h("div", _extends({}, BlockerContentInlineStyles.inner(blocker), {
    ref: ref
  }), isBackgroundVisible && h("div", BlockerContentInlineStyles.innerBackground(blocker)), h("div", BlockerContentInlineStyles.content(blocker), h(BlockerHeader, {
    closeIcon: closeIcon
  }), h("div", BlockerHeaderInlineStyles.headerSeparator(blocker)), h(BlockerBody, null), !!showFooter && h(Fragment, null, h("div", BlockerFooterInlineStyles.footerSeparator(blocker)), h(BlockerFooter, null))));
};

export { DefaultBlocker };