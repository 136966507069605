import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { BlockerGroupsInlineStyles } from ".";
import { useBlocker } from "../../contexts";
import { isUrl } from "@devowl-wp/headless-content-unblocker";

var BlockerCookieProperty = function BlockerCookieProperty(_ref) {
  var label = _ref.label,
      value = _ref.value,
      children = _ref.children;
  var blocker = useBlocker();
  var isValueUrl = typeof value === "string" && isUrl(value);
  var useValue = isValueUrl ? h("a", {
    href: value,
    style: _objectSpread({
      wordBreak: "break-all"
    }, BlockerGroupsInlineStyles.linkMore(blocker, false).style),
    target: "_blank",
    rel: "noopener noreferrer"
  }, value) : typeof value === "string" ? h("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value;
  return h("div", _extends({
    key: label
  }, BlockerGroupsInlineStyles.cookieProperty(blocker)), h("strong", null, label, ":\xA0"), useValue, !!children && h("div", null, children));
};

export { BlockerCookieProperty };