import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback } from "react";
import { useState } from "react";
import { useBlocker } from "../../contexts";
import { BlockerButtonsInlineStyles } from ".";
import { AsciiSpinner } from "..";

var BlockerButton = function BlockerButton(_ref) {
  var inlineStyle = _ref.inlineStyle,
      type = _ref.type,
      onClick = _ref.onClick,
      children = _ref.children,
      framed = _ref.framed,
      busyOnClick = _ref.busyOnClick;

  if (type === "hide") {
    return null;
  }

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isBusy = _useState2[0],
      setIsBusy = _useState2[1];

  var handleOnClick = useCallback(function (e) {
    if (!isBusy) {
      busyOnClick && setIsBusy(true);
      onClick === null || onClick === void 0 ? void 0 : onClick(e);
    }
  }, [onClick, isBusy, busyOnClick]);

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var banner = useBlocker();
  var interactionAttr = {
    onClick: handleOnClick,
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  };
  return h("div", _extends({}, type === "button" ? interactionAttr : {}, BlockerButtonsInlineStyles[inlineStyle](banner, isHover, framed)), h("span", type === "link" ? _objectSpread(_objectSpread({}, interactionAttr), {}, {
    style: {
      cursor: "pointer"
    }
  }) : {}, isBusy ? h(AsciiSpinner, null) : children));
};

export { BlockerButton };