import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useState } from "react";
import { BlockerFooterInlineStyles } from "./inlineStyles";
import { useBlocker } from "../../contexts";

var BlockerFooterLink = function BlockerFooterLink(_ref) {
  var children = _ref.children,
      anchorRest = _objectWithoutProperties(_ref, ["children"]);

  var blocker = useBlocker();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  return h("a", _extends({
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }, BlockerFooterInlineStyles.footerLink(blocker, isHover), anchorRest), children);
};

export { BlockerFooterLink };